import { CreateControllerFn } from '@wix/yoshi-flow-editor';

const createController: CreateControllerFn = async ({
  flowAPI,
  controllerConfig,
}) => {
  return {
    async pageReady(...args) {
      const { wixCodeApi, platformAPIs } = controllerConfig;
      const isEditor =
        flowAPI?.environment?.isEditor || flowAPI?.environment?.isEditorX;
      const biToken = platformAPIs?.bi?.biToken;
      const navigateToPricingPlansApp = async (pricingPlansIds: any[]) => {
        const { relativeUrl }: any = await wixCodeApi?.site?.getSectionUrl({
          sectionId: 'membership_plan_picker_tpa',
          appDefinitionId: '1522827f-c56c-a5c9-2ac9-00f9e6ae12d3',
        });
        wixCodeApi.location.to &&
          wixCodeApi?.location.to(
            relativeUrl +
              '?appSectionParams=' +
              JSON.stringify({ planIds: pricingPlansIds.join(',') }),
          );
      };
      const tempBaseUrl = controllerConfig.wixCodeApi.location.baseUrl;
      let baseUrl;
      const isEditor_X =
        controllerConfig?.config?.style?.styleParams?.booleans?.responsive ??
        false;
      if (tempBaseUrl.includes('editor.wix')) {
        baseUrl = '';
      } else if (tempBaseUrl.includes('editorx')) {
        baseUrl = '';
      } else if (tempBaseUrl.includes('wixsite.com/')) {
        baseUrl = tempBaseUrl.substring(0, tempBaseUrl.indexOf('wixsite.com/'));
        baseUrl = baseUrl + 'wixsite.com/';
      } else {
        baseUrl = controllerConfig.wixCodeApi.location.baseUrl;
      }
      const instance = controllerConfig.appParams.instance;
      const { appParams, compId } = controllerConfig;
      const user: any = {};

      const requestLogin = wixCodeApi?.user.promptLogin;
      let makePublic: any = () => {};
      let tempResult: any = {};
      try {
        // @ts-expect-error: Unreachable code error
        makePublic = wixCodeApi?.members?.currentMember?.makeProfilePublic;
        // @ts-expect-error: Unreachable code error
        tempResult = await wixCodeApi?.members?.currentMember?.getMember();
      } catch (e) {
        console.log('error ', e);
      }

      if (args[1]?.user?.currentUser.loggedIn) {
        try {
          user.instance = args[1]?.user?.currentUser?.instance;
        } catch (error) {
          console.error(error);
        }
      }
      user.id = args[1]?.user?.currentUser?.id;
      user.isLoggedIn = args[1]?.user?.currentUser?.loggedIn;
      user.role = args[1]?.user?.currentUser?.role;
      user.privacyStatus = tempResult?.privacyStatus || '';
      user.makePublic = makePublic;
      wixCodeApi?.user?.onLogin((userData: any) => {
        setTimeout(async () => {
          const signedInstance =
            wixCodeApi?.site?.getAppToken &&
            wixCodeApi?.site?.getAppToken(
              controllerConfig.appParams.appDefinitionId,
            );
          const loggedInUser: any = {};
          loggedInUser.id = userData?.id;
          loggedInUser.isLoggedIn = userData?.loggedIn;
          loggedInUser.role = userData?.role;
          controllerConfig.setProps({
            appParams,
            compId,
            user: loggedInUser,
            instance: signedInstance,
            biToken,
            fitToContentHeight: true,
            isEditor,
          });
        });
      });
      controllerConfig.setProps({
        isEditor_X,
        user,
        baseUrl,
        instance,
        requestLogin,
        navigateToPricingPlansApp,
        appParams,
        compId,
        biToken,
        fitToContentHeight: true,
        isEditor,
      });
    },
  };
};

export default createController;
